import { PDFDocument, rgb } from '@cantoo/pdf-lib';
import { ServeFieldInjection } from '../yjs-schema/property';
import { buildFieldName, FieldType } from '../signing/pdf-form-field';

export async function injectServeFields(doc: PDFDocument, injections?: ServeFieldInjection[]) {
  if (!injections?.length) return;

  const form = doc.getForm();
  for (const injection of injections) {
    const { top, left, width, height } = injection.location;
    const page = doc.getPage(injection.location.page);
    const field = form.createTextField(buildFieldName({ type: FieldType.Serve, fieldId: injection.field }));
    const pageHeight = page.getHeight();
    field.enableMultiline();
    field.addToPage(page, {
      x: left,
      y: pageHeight - top,
      width,
      height,
      borderWidth: 0,
      borderColor: rgb(1, 1, 1)
    });
    field.setFontSize(injection.font.size);
  }
}
