import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { DiffCollection } from '../util/form/DiffCollection';
import { InstanceHistory, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { PdfWorkerDocumentDefinition } from '../util/pdf/pdf-worker-types';
import { BelongingEntityMeta } from '../redux-reducers/entityMeta';

export enum DefinitionMode {
  Preview,
  Signing
}

export type CustomObjects = {
  agencyLogoImage?: Blob | string,
  completedAtMs?: number,
  marketingHeaderImage?: Blob | string
};

export interface GetterBinder<S> {
  get: () => S
}
export interface IPdfDefinitionProvider {
  shouldDebounce(): boolean;

  getDefinitionForPdfWorker(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    changeOriginal?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta,
    opts?: {
      metaOverride?: TransactionMetaData,
      finalBuildESigningMode?: boolean
    }
  ): Promise<PdfWorkerDocumentDefinition>;

  getDefinition(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    changeOriginal?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta
  ): Promise<TDocumentDefinitions>;

  getCoverPage(brand: EntityBrandFormConfig, objects?: CustomObjects): Promise<TDocumentDefinitions | undefined>;
  getCoverPageDefinitionForPdfWorker(brand: EntityBrandFormConfig, objects?: CustomObjects): Promise<TDocumentDefinitions>
}
