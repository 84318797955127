import {
  Annexure,
  ContentType,
  FileRef,
  ManifestData,
  ManifestType,
  MaterialisedPropertyData,
  UploadType
} from '@property-folders/contract';

import { processPdf } from './pdf/pdf-process';
import { FileStorage, FileType, StorageItemSyncStatus } from '../offline/fileStorage';
import { FileSync } from '../offline/fileSync';
import { v4 } from 'uuid';
import { Snapshot, UpdateFn } from 'immer-yjs/src';
import { AnyAction, Store } from 'redux';
import { AnnexuresSurgeon } from './annexures-surgeon';
import { DetermineReplacedRemovedLockedResult } from './dataExtractTypes';
import { Doc } from 'yjs';

type ImmerCallback<T extends Snapshot> = (userFunc: UpdateFn<T[]>) => void;

export async function deleteAnnexure (
  deleted: Annexure,
  usingPrevious:boolean,
  annexures: DetermineReplacedRemovedLockedResult<Annexure>[],
  updateAnnexures: ImmerCallback<Annexure> | undefined,
) {
  if (!deleted?.id) {
    return;
  }
  if (deleted?.noEditRemove || deleted?.noEditRemove) {
    console.warn('Annexure has been marked non-removable');
    return;
  }
  const fileRef = deleted.id;
  updateAnnexures?.(draft => {
    const surgeon = new AnnexuresSurgeon(draft, usingPrevious ? annexures : []);
    surgeon.remove({ id: deleted.id });
    surgeon.updateLabels();
  });

  // if the annexure was created from an uploaded document, we shouldn't be removing the file
  if (!deleted.preserveFile) {
    await FileStorage.delete(fileRef);
  }
}

export async function undoReplacementAnnexure({
  replacementAnnexureToBeRemoved,
  updateAnnexures,
  updateData
}: {
  replacementAnnexureToBeRemoved: Annexure,
  updateAnnexures: ImmerCallback<Annexure> | undefined,
  updateData?: ImmerCallback<MaterialisedPropertyData> | undefined // unlike updateAnnexures, this is intended to be fully optional. This will search the entire object for any property with a string matching the replacement ID and putting the restored original ID in its place. Does rely on them being UUIDs
}) {
  const idToSearchFor = replacementAnnexureToBeRemoved.id;
  let idRestored = replacementAnnexureToBeRemoved.replacingId;

  updateAnnexures?.(draft => {
    const surgeon = new AnnexuresSurgeon(draft, []);
    idRestored = surgeon.restoreReplacedAnnexureInVariation({ replacementAnnexureToBeRemoved });
  });

  if (idRestored) {
    updateData?.(draft=>{

      const iterObject = (node: NonNullable<Record<string, any>>) => {
        for (const propKey in node) {
          const prop = node[propKey];
          if (typeof prop === 'string' && prop === idToSearchFor) {

            node[propKey] = idRestored;
          }
          if (prop && typeof prop === 'object') {

            iterObject(prop); // should get both objects and array
          }
        }
      };
      iterObject(draft);
    });
  }

}

const updateFnCreateAnnexureFromUploadedDocument = ({
  file, name, binding, managed, uploadType, orderList, uploadTypeUnique, replaceId
}: {
  file: FileRef,
  name: string,
  uploadType?: UploadType,
  managed?: boolean,
  binding?: {
    path: string,
    root: string
  },
  orderList?: DetermineReplacedRemovedLockedResult<Annexure>[],
  // eliminate existing annexures with the same type but unexpected id
  uploadTypeUnique?: boolean,
  replaceId: string | undefined
}) => (draft: Annexure[]) => {
  const surgeon = new AnnexuresSurgeon(draft, orderList || []);
  surgeon.replace({
    replaceId,
    annexure: {
      id: file.id,
      contentType: ContentType.Pdf,
      name,
      preserveFile: true,
      uploadType,
      managed,
      binding
    }
  });
  if (uploadTypeUnique && uploadType) {
    surgeon.removeAllOfType({ type: uploadType, keepId: file.id });
  }
  surgeon.updateLabels();
};

export function createAnnexureFromUploadedDocument(props: {
  file: FileRef,
  name: string,
  uploadType?: UploadType,
  updateAnnexures: ImmerCallback<Annexure> | undefined,
  replace?: FileRef | string,
  managed?: boolean,
  binding?: {
    path: string,
    root: string
  },
  orderList?: DetermineReplacedRemovedLockedResult<Annexure>[],
  // eliminate existing annexures with the same type but unexpected id
  uploadTypeUnique?: boolean,
  replaceInVariation?: Annexure
}) {
  const {
    file,
    updateAnnexures,
    replace,
    replaceInVariation,
    managed,
    binding,
    uploadType,
    orderList,
    uploadTypeUnique
  } = props;
  const replaceId = replace
    ? typeof replace === 'string'
      ? replace
      : replace.id
    : undefined;

  // If we are told to use the special cross version replace, we need to copy the add and remove thing within updateAnnexures
  // from create annexure here. The main difference in this context, is this context is not also
  // responsible for uploads. This basically means surgeon.replace is defunct with respect to variations
  // document replacement.
  if (replaceInVariation) {
    updateAnnexures?.(updateFnCreateAnnexure({
      annexureId: file.id,
      fileRef: file,
      fileName: props.name,
      linkedFormId: undefined,
      coversheetText: '',
      uploadMode: {
        managed,
        binding,
        uploadType,
        preserveFile: true
      },
      uploadTypeUnique,
      orderList,
      replaceInVariation
    }));
  } else {
    updateAnnexures?.(updateFnCreateAnnexureFromUploadedDocument({ ...props, replaceId }));
  }

  return file.id;
}

const updateFnCreateAnnexure = ({
  coversheetText, file, fileRef,
  linkedFormId,
  noEditRemove, orderList, replaceInVariation,
  fileName, annexureId,
  uploadMode,
  uploadTypeUnique
}: {
  file?: File | Response,
  fileRef?: FileRef
  linkedFormId: string|undefined,
  coversheetText: string|undefined,
  noEditRemove?: boolean,
  orderList?: DetermineReplacedRemovedLockedResult<Annexure>[],
  replaceInVariation?: Annexure,
  fileName: string,
  annexureId: string,
  // For when upload is managed elsewhere, a-la createAnnexureFromUploadedDocument
  uploadMode?: {
    uploadType?: UploadType,
    managed?: boolean,
    binding?: {
      path: string,
      root: string
    },
    preserveFile?: boolean
  },
  uploadTypeUnique?: boolean
}) => (draft: Annexure[]) => {
  const contentType = file?.type??ContentType.Pdf;
  if (!contentType) {
    throw new Error('Cannot set annexure if no file given');
  }
  const surgeon = new AnnexuresSurgeon(draft, orderList || []);
  const replacedLabel = replaceInVariation?.label;
  const replacedId = replaceInVariation?.id;

  replaceInVariation && surgeon.restoreReplacedAnnexureInVariation({ annexureToBeRestored: replaceInVariation });

  surgeon.add({
    index: replaceInVariation?.id ? 0 : -1,
    annexure: {
      id: annexureId,
      contentType,
      name: fileName,
      ...(replacedId ? { replacingId: replacedId } : {}),
      ...(replacedLabel ? { label: replacedLabel } : {}),
      linkedFormId,
      coversheetText,
      ...(noEditRemove?{ noEditRemove }:undefined),
      ...uploadMode
    }
  });
  if (replacedId) {
    if (!replacedLabel) {
      throw new Error('Please provide an existing annexure definition');
    }
    surgeon.remove({ id: replacedId, replacedBy: annexureId });
  }
  if (uploadTypeUnique && uploadMode?.uploadType) {
    surgeon.removeAllOfType({ type: uploadMode?.uploadType, keepId: annexureId });
  }
  surgeon.updateLabels();
};

export async function createAnnexure (
  props : {
    file: File | Response,
    linkedFormId: string|undefined,
    coversheetText: string|undefined,
    property: MaterialisedPropertyData | undefined,
    formId: string,
    formCode: string,
    setErrorMessage?: (error: string)=>void,
    updateAnnexures?: ImmerCallback<Annexure> | undefined,
    updateAnnexureLabelsDraft?: (annexuresImmerDraft: Annexure[])=>void | undefined,
    fileSync: FileSync | undefined,
    nameOverride?: string,
    noEditRemove?: boolean,
    store: Store<unknown,AnyAction>,
    orderList?: DetermineReplacedRemovedLockedResult<Annexure>[],
    ydoc: Doc|undefined,
    replaceInVariation?: Annexure
  }
) {
  const { file,
    property,
    formId,
    formCode,
    setErrorMessage,
    updateAnnexures,
    fileSync,
    nameOverride,
    store,
    ydoc
  } = props;
  if (!property?.id) return;

  const fileBytes = await file.arrayBuffer();
  const pdf = await processPdf(new Uint8Array(fileBytes));
  const fileUrlSegments = file instanceof Response ? file.url?.split('/') : undefined;
  const fileName = nameOverride ?? (file instanceof File ? file.name?.replace(/\.[^/.]+$/, '') : fileUrlSegments && fileUrlSegments[fileUrlSegments.length-1]?.replace(/\.[^/.]+$/, '')) ?? 'Unknown name';
  if (!pdf?.pdf || pdf?.isEncrypted) {
    setErrorMessage?.(`Failed to attach ${fileName}. Please make sure PDF's are not encrypted and try again`);
    return;
  }

  const annexureId = v4();
  const fileid = annexureId;

  updateAnnexures?.(updateFnCreateAnnexure({ ...props, fileName, annexureId }));

  const manifestData: ManifestData = {
    manifestType: ManifestType.None
  };

  await FileStorage.write(
    fileid,
    FileType.PropertyFile,
    ContentType.Pdf,
    new Blob([pdf.pdf], { type: ContentType.Pdf }),
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId: property.id,
        formId,
        formCode
      }
    },
    { store, ydoc },
    manifestData,
    undefined,
  );

  FileSync.triggerSync(fileSync);

  return annexureId;
}

export function updateAnnexureLabelsImmer(draft: Annexure[], orderList?: DetermineReplacedRemovedLockedResult<Annexure>[]) {
  new AnnexuresSurgeon(draft, orderList || []).updateLabels();
}
