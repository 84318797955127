import { PDFDocument, EncryptedPDFError, LoadOptions } from '@cantoo/pdf-lib';

export const processPdf = async (
  pdfBytes: Uint8Array | ArrayBuffer,
  preFlatProcess?: (doc: PDFDocument) => Promise<void>,
  pdfLoadOptions?: LoadOptions
): Promise<{ pdf?: Uint8Array, isEncrypted: boolean } | undefined> => {
  if (pdfBytes.byteLength === 0) return undefined;

  try {
    const pdf = await PDFDocument.load(pdfBytes, pdfLoadOptions ?? {});
    if (preFlatProcess) {
      await preFlatProcess(pdf);
    }
    pdf.getForm().flatten();

    return { pdf: await pdf.save(), isEncrypted: false };
  } catch (e: unknown) {
    if (e instanceof EncryptedPDFError
      || (e && typeof e === 'object' && 'msg' in e && typeof e.msg === 'string' && e.msg.includes('encrypted'))) {
      return { isEncrypted: true };
    }

    if ((e as Error).message === 'Password incorrect') {
      return { isEncrypted: true };
    }

    return undefined;
  }
};
