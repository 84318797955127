import { getPageInfo, PdfInformationExtractor } from '../util/pdf';
import { degrees, PDFDocument, rgb, StandardFonts } from '@cantoo/pdf-lib';
import { CoordinateMath, NoReflection } from '../util/coords';

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

function hexToNormalised (str: string) {
  return parseInt(str, 16)/255;
}

const borderColour: [number, number, number] = ['fc', 'ce', '14'].map(hexToNormalised);
const backgroundColour: [number, number, number] = ['fc', 'fc', 'ca'].map(hexToNormalised);
export async function highlightTemplateForm1Fill(doc: PDFDocument) {

  const fp = await (new PdfInformationExtractor(doc)).getFieldPositions();
  const form = doc.getForm();
  const font = await doc.embedFont(StandardFonts.HelveticaBold);

  for (const field of fp ) {
    if (!field.id.startsWith('SERVE')) continue;
    const encodedFieldData = field.id.split('.');
    const fieldName = encodedFieldData[1];
    if (!fieldName) continue;
    const niceText = toTitleCase(fieldName.replace('_', ' '));
    for (const position of field.positions) {
      const { page, dims } = getPageInfo(doc, position.page);
      const rotate = degrees(dims.degrees);
      const rectanglePoint = CoordinateMath.orientToPage({
        x: position.x,
        y: position.y
      }, dims, NoReflection);
      const textPoint = CoordinateMath.orientToPage({
        x: position.x+2,
        y: position.y+position.height/2-3.5
      }, dims, NoReflection);

      page.drawRectangle({
        y: rectanglePoint.y,
        x: rectanglePoint.x,
        height: position.height,
        width: position.width,
        opacity: 1,
        borderOpacity: 1,
        borderWidth: 1.5,
        borderColor: rgb(...borderColour),
        color: rgb(...backgroundColour),
        rotate
      });

      page.drawText(niceText, {
        x: textPoint.x,
        y: textPoint.y,
        font,
        size: 9,
        color: rgb(...borderColour),
        rotate

      });

    }
    const acroField = form.getFieldMaybe(field.id);
    if (!acroField) continue;
    form.removeField(acroField);
  }
}

