import { JsonFormattedLogger } from '../logging';
import { Annexure, UploadType } from '@property-folders/contract';
import _ from 'lodash';
import AlphanumericEncoder from 'alphanumeric-encoder';
import { DataGeneration, DetermineReplacedRemovedLockedResult } from './dataExtractTypes';
import { byMapperFn } from './sortComparison';

export interface AnnexureSummary {
  id: string,
  label: string,
  name: string,
  uploadType?: UploadType
}

export class AnnexuresSurgeon {
  private log?: JsonFormattedLogger;
  private encoder = new AlphanumericEncoder();

  constructor(
    private workingSet: Annexure[],
    private historical: DetermineReplacedRemovedLockedResult<Annexure>[],
    logging: boolean = false
  ) {
    this.log = logging ? new JsonFormattedLogger('AnnexuresSurgeon') : undefined;
    if (logging) {
      console.trace('constructor');
    }
    this.log?.info('initial state', { workingSet, historical });
  }

  public add({ annexure, index }: { annexure: Annexure, index: number }) {
    this.log?.info('add', { annexure, index });

    // was it deleted in this document?
    const idxExisting = _.findIndex(this.workingSet, e => e.id === annexure.id);
    if (idxExisting >= 0) {
      if (isRemoved(this.workingSet[idxExisting])) {
        this.log?.info('delete removed marker item');
        this.workingSet.splice(idxExisting, 1);
        return;
      }

      this.log?.info('already exists');
      return;
    }

    const historical = _.find(this.historical, e => e.id === annexure.id);
    let setRestoreMarker = false;
    if (historical) {
      this.log?.info('todo: handle historical?', { historical });
      switch (historical.state) {
        case DataGeneration.Added: // 2
        case DataGeneration.Restored: // 3
        case DataGeneration.CarriedOver: // 0
        case DataGeneration.Replaced: // 4
          this.log?.info('historical added/restored/carried over. no-op?');
          return;
        case DataGeneration.Removed: // 1
          this.log?.info('historical removed. insert new with restore marker');
          setRestoreMarker = true;
          break;
      }
    }

    const item: Annexure = setRestoreMarker ? { ...annexure, _restoredMarker: true } : annexure;
    if (index >= 0) {
      this.log?.info('insert at index', { index });
      this.workingSet.splice(index, 0, item);
    } else {
      this.log?.info('push to end');
      this.workingSet.push(item);
    }
  }
  public restoreReplacedAnnexureInVariation(props: {replacementAnnexureToBeRemoved: Annexure}): string;
  public restoreReplacedAnnexureInVariation(props: {annexureToBeRestored: Annexure}): string;
  public restoreReplacedAnnexureInVariation({ replacementAnnexureToBeRemoved, annexureToBeRestored }: {replacementAnnexureToBeRemoved?: Annexure, annexureToBeRestored?: Annexure}) {
    if (!replacementAnnexureToBeRemoved && !annexureToBeRestored) {
      console.error('No valid props were passed for annexure restore');
      return;
    }
    let removingAnnexure = replacementAnnexureToBeRemoved;
    if (!replacementAnnexureToBeRemoved && annexureToBeRestored) {
      const maybeRemovingAnnexure = this.workingSet.find(a => a.replacingId === annexureToBeRestored.id);
      if (!maybeRemovingAnnexure) {
        // This is actually a thing that happens when you're replacing a historical annexure that isn't in the current, so it's fine
        return;
      }
      removingAnnexure = maybeRemovingAnnexure;
    }
    let restoredId = '';
    const removalMarkerIndex = this.workingSet.findIndex(a=>a.id === removingAnnexure?.replacingId);
    if (removalMarkerIndex >= 0) {
      const removalMarker = this.workingSet.splice(removalMarkerIndex, 1);

      restoredId = removalMarker[0]?.id;
    }
    const defunctReplacementIndex = this.workingSet.findIndex(a=>a.id === removingAnnexure?.id);
    if (defunctReplacementIndex >= 0) {
      this.workingSet.splice(defunctReplacementIndex, 1);
    }
    return restoredId;
  }

  public remove({ id, replacedBy }: { id: string, replacedBy?: string }): number {
    const idxExisting = _.findIndex(this.workingSet, e => e.id === id);
    if (isRemoved(this.workingSet[idxExisting])) {
      this.log?.info('already removed');
      return idxExisting;
    }

    const historical = _.find(this.historical, e => e.id === id);
    if (historical) {
      this.log?.info('todo: handle historical?', { historical });
      switch (historical.state) {
        case DataGeneration.Replaced:
        case DataGeneration.CarriedOver: // 0
        case DataGeneration.Restored: // 3
        {
          this.log?.info('/restored/carried over, add removal marker');
          const newItem = { ...historical.data, id, _removedMarker: true };
          if (replacedBy) newItem._replacedBy = replacedBy;
          this.workingSet.push(newItem);
          break;
        }
        case DataGeneration.Added: // 2
          this.log?.info('historical added/restored. do we need to add a removal marker?');
          break;
        case DataGeneration.Removed: // 1
          this.log?.info('historical already removed. no-op?');
          break;
      }
    }

    if (idxExisting < 0) {
      this.log?.info('nothing to remove');
      return -1;
    }

    this.log?.info('remove at index', { idxExisting });
    this.workingSet.splice(idxExisting, 1);
    return idxExisting;
  }

  public removeAllOfType({ keepId, type }: { keepId?: string, type: UploadType }) {
    const toRemove = this.getActiveSummary().filter(x => x.uploadType === type && x.id !== keepId);
    if (toRemove.length) {
      this.log?.info('removeAllOfType', { keepId, toRemove });
    }
    for (const { id } of toRemove) {
      this.remove({ id });
    }
  }

  public dedupe({ id }: { id: string }) {
    this.log?.info('dedupe', { id });
    const idxFirst = _.findIndex(this.workingSet, e => e.id === id);
    for (let idx = this.workingSet.length - 1; idx > idxFirst; idx--) {
      this.workingSet.splice(idx, 1);
    }
  }

  public replace({ replaceId, annexure }: { replaceId?: string, annexure: Annexure }) {
    this.log?.info('replace', { replaceId, annexure });
    const index = replaceId ? this.remove({ id: replaceId }) : -1;
    this.add({ annexure, index });
  }

  public labelStats(useHistorical = true, useWorkingSet = false) {
    const existingLabels: string[] = [];
    if (useHistorical) {
      for (const ol of this.historical) {
        if (ol.data.label) {
          existingLabels.push(ol.data.label);
        }
      }
    }

    if (useWorkingSet) {
      for (const item of this.workingSet) {
        if (item.label) {
          existingLabels.push(item.label);
        }
      }
    }

    if (!existingLabels.length) {
      return {
        existingLabels,
        max: 0,
        maxLabel: '',
        next: 1,
        nextLabel: 'A'
      };
    }

    const max = Math.max(...existingLabels.map(l => this.encoder.decode(l) ?? 0));
    return {
      existingLabels,
      max,
      maxLabel: this.encoder.encode(max),
      next: max+1,
      nextLabel: this.encoder.encode(max + 1)
    };
  }

  public updateLabels() {

    const labelMap = new Map<string, string>();
    for (const item of this.historical) {
      if (item.data.label) {
        labelMap.set(item.id, item.data.label);
      }
    }
    const stats = this.labelStats(true, false);
    let next = stats.next;

    for (const item of this.workingSet) {
      if ('_removedMarker' in item && item._removedMarker) {
        continue;
      }
      if ('replacingId' in item) {

        const replaced = this.historical.find(hi => hi.id === item.replacingId);
        if (replaced) item.label = replaced.data.label;
        continue;
      }

      const existing = labelMap.get(item.id);
      if (existing) {
        item.label = existing;
      } else {
        item.label = this.encoder.encode(next);
        next = next + 1;
      }
    }
  }

  public getActiveSummary(): AnnexureSummary[] {
    const items = new Map<string, { id: string, label?: string, labelNum: number, name?: string, uploadType?: UploadType, removed: boolean }>();

    for (const { state, id, data } of this.historical) {
      if (!data.id) continue;

      items.set(data.id, {
        id: data.id,
        label: data.label,
        labelNum: data.label ? this.encoder.decode(data.label) || 0 : 0,
        name: 'name' in data && data.name ? data.name : undefined,
        removed: state === DataGeneration.Removed || ('_removedMarker' in data && data._removedMarker),
        uploadType: data.uploadType
      });
    }

    for (const item of this.workingSet) {
      const id = item.id;
      const current = items.get(id);
      const label = current?.label || item.label;
      const labelNum = label ? this.encoder.decode(label) || 0 : 0;
      const name = current?.name || ('name' in item ? item.name : undefined);
      const uploadType = current?.uploadType || item.uploadType;
      const prevRemoved = !!current?.removed;
      const curRemoved = ('_removedMarker' in item && item._removedMarker);
      const curRestored = ('_restoredMarker' in item && item._restoredMarker);
      const removed = curRemoved || (prevRemoved && !curRestored);

      items.set(id, { id, label, labelNum, name, uploadType, removed });
    }

    return [...items.values()]
      .filter(x => !x.removed || !x.label)
      .sort(byMapperFn(x => x.labelNum))
      .map(x => ({
        id: x.id,
        label: x.label!,
        name: x.name || '',
        uploadType: x.uploadType
      }));
  }
}

function isRemoved(annexure?: Annexure) {
  return annexure && '_removedMarker' in annexure && annexure._removedMarker;
}
