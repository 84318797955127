import { LinkBuilder } from '../util/LinkBuilder';
import { ContentType } from '@property-folders/contract';
import { WrappedFetch } from './wrappedFetch';
import { blobTob64 } from '../util/dataExtract';

export class ReaformsUserAssets {
  public static async getEntityLogo(entityId: number) {
    // should be either an image or HTTP 307 to pre-signed url
    const result = await WrappedFetch.bare(LinkBuilder.restApi(`/entities/${entityId}/logo`));
    return {
      data: await result.blob(),
      contentType: result.headers.get('content-type') || ContentType.Jpeg
    };
  }

  public static async getEmailLogo({ entityUuid, signal }: {entityUuid: string, signal?: AbortSignal}) {
    // should be either an image or HTTP 307 to pre-signed url
    const result = await WrappedFetch.bare(LinkBuilder.restApi(`/entities/${entityUuid}/email-logo`), { signal });
    return result;
  }

  public static async putEmailLogo({ entityUuid, data }: {entityUuid: string, data: Blob}) {

    const blobB64 = await blobTob64(data);
    const result = await WrappedFetch.bare(
      LinkBuilder.restApi(`/entities/${entityUuid}/email-logo`),
      { method: 'put', body: JSON.stringify({ imageData: blobB64 }) }
    );
    return result;
  }

  public static async deleteEmailLogo({ entityUuid, resetToDefault }: {entityUuid: string, resetToDefault?: boolean}) {
    const queryStr = resetToDefault ? '?resetDefault=1' : '';
    const result = await WrappedFetch.bare(
      LinkBuilder.restApi(`/entities/${entityUuid}/email-logo${queryStr}`),
      { method: 'delete' }
    );
    return result;
  }
}
