import { MaterialisedPropertyData, SaleDetailsType, SaleMethod } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import Decimal from 'decimal.js';
import { Predicate } from '../../../../predicate';
import { getPathParentAndIndex, normalisePathToStrArray } from '../../../../util/pathHandling';
import { setDepositTime } from './setDepositTime';

const iNN = Predicate.isNotNullish;

/**Enforces Prescribed Minimum Advertising Price and 10% range on advertised price
 *
 * input only fields: sale.agentEstPrc, sale.vendorPrc, sale.saleMethod
 * input and output fields: sale.advertPrc, sale.advertPrcUpper
 * output only fields: cgtEnable, auctionFeeSwitches.applicable, hideFinance, hideSaleRequired
 */
export const advertRestrict: FieldGroupFn = (fieldId, updatedPath, immerProxy: MaterialisedPropertyData, variationsSnapshot, history, previousState) => {
  const updatedPathSegs = normalisePathToStrArray(updatedPath);
  if (updatedPathSegs[0] !== 'sale') {
    // The only inputs we use are in the sale map, so we'll just ignore anything else
    return;
  }
  // s short for sale (structure)
  const { parent: s, indexer } = getPathParentAndIndex(updatedPath, immerProxy, true) as {parent: SaleDetailsType, indexer: keyof SaleDetailsType};
  if (!s || !indexer) {
    return;
  }
  // saleMethod is the only point not assessed as a number
  if (!(indexer === 'saleMethod' || typeof s[indexer] === 'number')) {
    return; // Do nothing. No valid actions when value is updated to nothing useful
  }

  // We perform this check here, because the user needs to be explicitly updating this value for us
  // to set it, rather than an affect unsetting it because the page loaded or something silly
  if (indexer === 'saleMethod') {
    if (!s.saleMethod) {
      // A 'Clear Selection was used'
      delete immerProxy?.auctionFeeSwitches?.applicable;
      if (immerProxy?.contractSpecial?.hideSaleRequired && immerProxy?.contractSpecial?.hideFinance) {
        delete immerProxy.contractSpecial.hideSaleRequired;
        delete immerProxy.contractSpecial.hideFinance;
      }
    }
    if (!immerProxy.coolingOff) immerProxy.coolingOff = {};
    if (s.saleMethod === SaleMethod.Auction) {
      if (!immerProxy.auctionFeeSwitches) {
        immerProxy.auctionFeeSwitches = {};
      }
      immerProxy.auctionFeeSwitches.applicable = true;

      if (!immerProxy.contractSpecial) immerProxy.contractSpecial = {};
      immerProxy.contractSpecial.hideSaleRequired = true;
      immerProxy.contractSpecial.hideFinance = true;
      immerProxy.coolingOff.purchaserRight = 'no';
    } else if (immerProxy?.contractSpecial?.hideSaleRequired && immerProxy?.contractSpecial?.hideFinance) {
      delete immerProxy.contractSpecial.hideSaleRequired;
      delete immerProxy.contractSpecial.hideFinance;
      immerProxy.coolingOff.purchaserRight = 'yes';
    }
    // We also need to chain the group function
    setDepositTime(fieldId, updatedPath, immerProxy, variationsSnapshot, history, previousState);
  }

  // We don't accept trash anymore. Too much fussing around for what are clearly intended to be numerical values
  if (typeof s.advertPrcUpper !== 'number') {
    delete s.advertPrcUpper;
  }
  if (typeof s.advertPrc !== 'number') {
    delete s.advertPrc;
  }

  // pmap: Prescribed Minimum Advertising Price
  const pmap = typeof s.agentEstPrc === 'number' && typeof s.vendorPrc === 'number' && Math.max(s.agentEstPrc, s.vendorPrc);

  if (pmap && iNN(s.advertPrc) && s.advertPrc < pmap) {
    delete s.advertPrc;
  }
  if (pmap && iNN(s.advertPrcUpper) && s.advertPrcUpper < pmap) {
    delete s.advertPrcUpper;
  }

  // The general rule now is that, if the user value becomes invalid, we set it back to unfilled
  // and thus default.
  if (!pmap && ['agentEstPrc', 'vendorPrc'].includes(indexer)) {
    return;
  }

  // Invalidate the other value if it should fall outside the 10% range. Don't set any values

  // We use the Decimal library here to avoid float rounding errors. Don't want there to be legal
  // rammifications because something was ever so slightly more than 10% by using a rougher solution
  if (s.advertPrc && s.advertPrcUpper && (s.advertPrcUpper < s.advertPrc || s.advertPrcUpper > Decimal.mul(s.advertPrc, 1.1).toNumber())) {
    if (indexer === 'advertPrc') delete s.advertPrcUpper;
    if (indexer === 'advertPrcUpper') delete s.advertPrc;
  }

};
